import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Recaptcha from './Recaptcha';
import '../styles/Contact.css';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_s4am5ck', 'template_fjh5lhq', form.current, 'uv9KW8uHYU7mFCCaX')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
 <form style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
        backgroundColor: "white",
        borderRadius: "1rem",
        boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.1)"
      }}
      ref={form}
      onSubmit={sendEmail}
    >
      <p>
      or send email to{" "}
      <a href="mailto:vvagraphics@gmail.com">vvagraphics@gmail.com</a>
    </p>
    
      <label style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
        Name
      </label>
      <input
        style={{
          marginTop: "0.5rem",
          padding: "0.5rem",
          width: "50%",
          border: "1px solid gray",
          borderRadius: "0.5rem"
        }}
        
        type="text"
        name="user_name"
      />
      <label style={{ fontSize: "1.25rem", fontWeight: "bold", marginTop: "1rem" }}>
        Email
      </label>
      <input
        style={{
          marginTop: "0.5rem",
          padding: "0.5rem",
          width: "50%",
          border: "1px solid gray",
          borderRadius: "0.5rem"
        }}
        type="email"
        name="user_email"
      />
      <label style={{ fontSize: "1.25rem", fontWeight: "bold", marginTop: "1rem" }}>
        Message
      </label>
      <textarea
        style={{
          marginTop: "0.5rem",
          padding: "0.5rem",
          height: "7rem",
          width: "80%",
          border: "1px solid gray",
          borderRadius: "0.5rem"
        }}
        name="message"
      />
      <Recaptcha />
      <input
        style={{
          marginTop: "1rem",
          padding: "0.5rem",
          backgroundColor: "indigo",
          color: "white",
          borderRadius: "0.5rem",
          width: "40%",
          fontWeight: "bold",
          fontSize: "1.5rem",
          transition: "background-color 0.2s ease-in-out",
          cursor: "pointer"
        }}
        type="submit"
        value="Send"
      />
    </form>
  );
};
    
export default ContactUs;
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = () => {
  const [token, setToken] = useState(null);

  const onChange = (value) => {
    setToken(value);
    console.log("Captcha value:", value);
  };

  return (
    <div>
      <ReCAPTCHA sitekey="6LfQZeUkAAAAAIjwDEROKkVIxzyb0_qc8cYXJo6i" onChange={onChange} />
      {token ? `reCAPTCHA token: ${token}` : "Loading reCAPTCHA..Please wait"}
    </div>
  );
};

export default Recaptcha;

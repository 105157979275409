import React from "react";
import MotionGallery from "../components/MotionGallery";

const TabMotion = ({ colors, handleHover }) => {
  return (
    <div className="slider" id="tab-motion">
      <h1
        className="sliderHeader"
        style={{ color:colors[19] }}
      >MOTION</h1>
      <h2>
        Use of animation, video, and other moving elements to create engaging
        digital content, including explainer videos, animated logos, and motion
        graphics.
      </h2>
      <MotionGallery colors={colors}/>
    </div>
  );
};

export default TabMotion;

import React from "react";


const Filller = () => {
  return (
    <div className="slider2" id="tab-fill">

    </div>
  );
};

export default Filller;

import React, { useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
  content: {
  position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '95%',
    maxHeight: '95%',
    padding: 0,
    border: 'none',
    background: 'transparent',
    overflow: 'visible',
    pointerEvents: 'none',
  },
};



const images = [
  { src: '/images/motion/mt1.webp', link: 'https://cdn.midjourney.com/1a7129e3-2609-4a73-82e4-8d4d65051bda/0_3.png' },
  { src: '/images/motion/mt4.png', link: 'https://cdn.midjourney.com/e0a97391-8f26-4b86-a560-bdb803b52315/0_0.webp' },
  { src: '/images/motion/mt2.png', link: 'https://cdn.midjourney.com/5352c273-d8d1-4e73-921f-d9d512f5d14b/0_0.webp' },
  { src: '/images/motion/motion3.png', link: 'https://cdn.midjourney.com/66ea5f9d-9c41-4648-b80b-a8656d980ce2/0_1.webp' },
];

const MotionGallery = ({ colors }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  function openModal(image) {
    setSelectedImage(image);
    setModalIsOpen(true);
  }

  function closeModal() {
    setSelectedImage(null);
    setModalIsOpen(false);
  }

  return (
      <div className="gallery">
      {images.map((image, index) => (
        <div
          className="gallery-item"
          style={{ border: `.2rem solid ${colors[19]}` }}
          key={index}
        >
          <img
            src={image.src}
            alt=""
            onClick={() => openModal(image)} 
            style={{ cursor: 'pointer' }}
          />
          <div className="button-wrapper">
            <a
              href={image.link}
              target="_blank"
              rel="noreferrer"
              className="prevLargerBtn"
              style={{
                color: colors[20],
                textDecoration: 'none',
                display: 'inline-block',
                outline: `.2rem solid ${colors[19]}`,
              }}
            >
              LINK
            </a>
          </div>
        </div>
      ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
      >
        {selectedImage && (
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              src={selectedImage.src}
              alt=""
              style={{
                maxWidth: '100vw',
                maxHeight: '100vh',
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              }}
            />
            <button
              className="modalBtn"
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                border: 'none',
                borderRadius: '50%',
                padding: '8px 16px',
                cursor: 'pointer',
                zIndex: 1,
                pointerEvents: 'auto',
              }}
            >
              Close
            </button>
    </div>
  )}
</Modal>
      
    </div>
  );
};

export default MotionGallery;

import React, { useState, useEffect } from "react";
import "./globals.css";
import styles from "./page.module.css";
import TabPrint from "./sections/TabPrint";
import TabDigital from "./sections/TabDigital";
import TabBranding from "./sections/TabBranding";
import TabUIUX from "./sections/TabUIUX";
import TabMotion from "./sections/TabMotion";
import Navigation from "./sections/Navigation";
import Footer from "./sections/Footer";
import Filller from "./sections/Filller";

function App() {
  const [colors, setColors] = useState({});
  const [resetClicked, setResetClicked] = useState(false);

  function handleHover(index) {
    const newColors = { ...colors };
    newColors[index] = getRandomColor();
    setColors(newColors);
  }

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    const sliderHeaders = document.querySelectorAll(".sliderHeader");
    const navTab = document.querySelectorAll(".nav-tab");
    const stickyToTop = document.querySelectorAll(".stickyToTop");
    const stickyToTopElements = document.querySelectorAll(".stickyToTop a");
    const galleryItem = document.querySelectorAll(".gallery-item");
    const prevLargerBtn = document.querySelectorAll(".prevLargerBtn");
    const contactBtn = document.querySelectorAll(".contactBtn");

///////////////Mouseover | Hover///////////////////////////
    sliderHeaders.forEach((sliderHeader) => {
      sliderHeader.addEventListener("mouseenter", () => {
        if (!resetClicked) {
          sliderHeader.style.color = colors[20];
        } else {
          sliderHeader.style.color = "#e9c46a";          
        }
      });

      sliderHeader.addEventListener("mouseleave", () => {
        if (!resetClicked) {
          sliderHeader.style.color = colors[19];

        } else {
          sliderHeader.style.color = "#a7c957";
        }
      });
    });
    
    stickyToTop.forEach((stickyToTop) => {
      stickyToTop.addEventListener("mouseenter", () => {
        if (!resetClicked) {
          stickyToTop.style.border = `.3rem solid ${colors[19]}`;
        } else {
          stickyToTop.style.border = `.3rem solid #a7c957`;          
        }
      });
      stickyToTop.addEventListener("mouseleave", () => {
        if (!resetClicked) {
          stickyToTop.style.border = `.2rem solid ${colors[19]}`;
        } else {
          stickyToTop.style.border = `.2rem solid #a7c957`;          
        }
      });
    });

        stickyToTopElements.forEach((element) => {
      element.addEventListener("mouseenter", () => {
        if (!resetClicked) {
          element.style.color =colors[20];
          
        } else {
          element.style.color = "#f4a261";          
          
        }
      });

      element.addEventListener("mouseleave", () => {
        if (!resetClicked) {
          element.style.color = colors[20];
        } else {
          element.style.color = "#f2e8cf";
          
        }
      });
    });

        galleryItem.forEach((galleryItem) => {
      galleryItem.addEventListener("mouseenter", () => {
        if (!resetClicked) {
          galleryItem.style.border = `.2rem solid ${colors[19]}`;
        } else {
          galleryItem.style.border = `.2rem solid #a7c957`;          
        }
      });

      galleryItem.addEventListener("mouseleave", () => {
        if (!resetClicked) {
          galleryItem.style.border = `.2rem solid ${colors[19]}`;
        } else {
          galleryItem.style.border = `.2rem solid #a7c957`;
        }
      });
    });

    prevLargerBtn.forEach((prevLargerBtn) => {
      prevLargerBtn.addEventListener("mouseenter", () => {
        if (!resetClicked) {
          prevLargerBtn.style.outline = `.4rem solid ${colors[19]}`;
          prevLargerBtn.style.color = colors[20];
        } else {
          prevLargerBtn.style.outline = `.4rem solid #a7c957`;
          prevLargerBtn.style.color = "#f4a261";          
        }
      });
      prevLargerBtn.addEventListener("mouseleave", () => {
        if (!resetClicked) {
          prevLargerBtn.style.outline = `.2rem solid ${colors[19]}`;
          prevLargerBtn.style.color = colors[20];
        } else {
          prevLargerBtn.style.outline = `.2rem solid #a7c957`;   
          prevLargerBtn.style.color = "#f2e8cf";       
        }
      });
    });

        contactBtn.forEach((contactBtn) => {
      contactBtn.addEventListener("mouseenter", () => {
        if (!resetClicked) {
          contactBtn.style.outline = `.5rem solid ${colors[19]}`;
          contactBtn.style.color = colors[20];
        } else {
          contactBtn.style.outline = `.5rem solid #a7c957`;
          contactBtn.style.color = "#f4a261";          
        }
      });
      contactBtn.addEventListener("mouseleave", () => {
        if (!resetClicked) {
          contactBtn.style.outline = `.3rem solid ${colors[19]}`;
          contactBtn.style.color = colors[20];
        } else {
          contactBtn.style.outline = `.3rem solid #a7c957`;   
          contactBtn.style.color = "#f2e8cf";       
        }
      });
    });

    navTab.forEach((navTab) => {
      navTab.addEventListener("mouseenter", () => {
        if (!resetClicked) {
          navTab.style.backgroundColor = colors[20];
        } else {
          navTab.style.backgroundColor = "#a7c957";
        }
      });

      navTab.addEventListener("mouseleave", () => {
        navTab.style.backgroundColor = "transparent";
      });
    });
    
  }, [colors, resetClicked]);


///////////////Reset Sections////////////////////////////////////////////
  const resetStyles = () => {
    const navTabs = document.querySelectorAll(".nav-tab");
    navTabs.forEach((navTab) => {
      navTab.style.backgroundColor = "";
      navTab.style.color = "";
    });
    const sliderHeaders = document.querySelectorAll(".sliderHeader");
    sliderHeaders.forEach((sliderHeader) => {
      sliderHeader.style.backgroundColor = "";
      sliderHeader.style.color = "";
    });

    const stickyToTop = document.querySelectorAll(".stickyToTop");
    stickyToTop.forEach((stickyToTop) => {
      stickyToTop.style.border = "";
      
    });
        const stickyToTopElements = document.querySelectorAll(".stickyToTop a");
    stickyToTopElements.forEach((element) => {
      element.style.color = "";
      
    });
    const galleryItem = document.querySelectorAll(".gallery-item");
    galleryItem.forEach((galleryItem) => {
      galleryItem.style.border = "";
    });

       const prevLargerBtn = document.querySelectorAll(".prevLargerBtn");
    prevLargerBtn.forEach((prevLargerBtn) => {
      prevLargerBtn.style.outline = "";
      prevLargerBtn.style.color = "";
    });    

       const contactBtn = document.querySelectorAll(".contactBtn");
    contactBtn.forEach((contactBtn) => {
      contactBtn.style.outline = "";
      contactBtn.style.color = "";
    });   
    
      const copycontainer = document.querySelectorAll(".copycontainer");
    copycontainer.forEach((m) => {
      m.style.color = "";
    });

    const ms = document.querySelectorAll(".nav span");
    ms.forEach((m) => {
      m.style.color = "";
    });

    

    const navContainerBorder = document.querySelectorAll(".nav-container");
    navContainerBorder.forEach((navContainerBorder) => {
      navContainerBorder.style.borderColor = "";
      navContainerBorder.style.color = "";
    });


    setResetClicked(true);
  };
  const color20 = colors[20];

  useEffect(() => {
    setResetClicked(false);
  }, [color20]);

  


  return (
    <main className={styles.main}>
      <div>
        <Navigation
          colors={colors}
          handleHover={handleHover}
          resetStyles={resetStyles}
        />
        
        <Filller colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <TabPrint colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <Filller colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <TabDigital colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <Filller colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <TabMotion colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <Filller colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <TabUIUX colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <Filller colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <TabBranding colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <Filller colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
        <Footer colors={colors} handleHover={handleHover} resetStyles={resetStyles} />
      </div>
    </main>
  );
}

export default App;

import React from "react";
import UiUxGallery from "../components/UiUxGallery";

const TabUIUX = ({ colors, handleHover }) => {
  return (
    <div className="slider" id="tab-uiux">
      <h1
        className="sliderHeader"
        style={{ color:colors[19] }}
      >UI/UX</h1>
      <h2>
        Designing the user interface of digital products, such as websites and
        mobile applications, to ensure a positive user experience, involving
        intuitive navigation, visually appealing layouts, and interactive
        elements.
      </h2>
      <UiUxGallery colors={colors}/>
    </div>
  );
};

export default TabUIUX;

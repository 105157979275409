import React from "react";
import PrintGallery from "../components/PrintGallery";

const TabPrint = ({ colors }) => {
  return (
    <div className="slider" id="tab-print">
      <h1
        className="sliderHeader"
        style={{ color:colors[19] }}
      >PRINT</h1>
      
      <h2>
        Designs for print materials such as brochures, flyers, posters, business
        cards, and packaging.
      </h2>
      <PrintGallery  colors={colors}/>
    </div>
  );
};

export default TabPrint;

import React from "react";
import "../globals.css";

function Navigation({ colors, handleHover, resetStyles }) {
  return (
    <div className="nav" id="nav-home">
      <button className="resetbtn" onClick={resetStyles}>RESET COLOR</button>

      <h1>
        <span
          class="highlighted1"
          onMouseEnter={() => handleHover(19)}
          style={{ color: colors[19], backgroundColor: colors[21] }}
        >
          VVA
        </span>
        <span
          class="highlighted2"
          onMouseEnter={() => handleHover(20)}
          style={{ color: colors[20], backgroundColor: colors[22] }}
        >
          GRAPHICS
        </span>
      </h1>
      <h3 className="spanloader">
        <span
          className="m"
          onMouseEnter={() => handleHover(1)}
          style={{ color: colors[1] }}
        >
          S
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(2)}
          style={{ color: colors[2] }}
        >
          T
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(3)}
          style={{ color: colors[3] }}
        >
          E
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(4)}
          style={{ color: colors[4] }}
        >
          F
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(5)}
          style={{ color: colors[5] }}
        >
          A
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(6)}
          style={{ color: colors[6] }}
        >
          N
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(7)}
          style={{ color: colors[7] }}
        >
          &apos;
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(8)}
          style={{ color: colors[8] }}
        >
          S
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(9)}
          style={{ color: colors[9] }}
        >
          &nbsp;
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(10)}
          style={{ color: colors[10] }}
        >
          P
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(11)}
          style={{ color: colors[11] }}
        >
          O
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(12)}
          style={{ color: colors[12] }}
        >
          R
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(13)}
          style={{ color: colors[13] }}
        >
          T
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(14)}
          style={{ color: colors[14] }}
        >
          F
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(15)}
          style={{ color: colors[15] }}
        >
          O
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(16)}
          style={{ color: colors[16] }}
        >
          L
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(17)}
          style={{ color: colors[17] }}
        >
          I
        </span>
        <span
          className="m"
          onMouseEnter={() => handleHover(18)}
          style={{ color: colors[18] }}
        >
          O
        </span>
      </h3>

      <section id="products"></section>

      <div className="nav-container" style={{ borderColor: colors[19] }}>
        <a className="nav-tab" style={{ color: colors[19] }} href="#tab-print">
          &bull;PRINT
        </a>
        <a
          className="nav-tab"
          style={{ color: colors[19] }}
          href="#tab-digital"
        >
          &bull;DIGITAL
        </a>
        <a className="nav-tab" style={{ color: colors[19] }} href="#tab-motion">
         &bull;MOTION
        </a>
        <a className="nav-tab" style={{ color: colors[19] }} href="#tab-uiux">
          &bull;UI/UX
        </a>
        <a
          className="nav-tab"
          style={{ color: colors[19] }}
          href="#tab-branding"
        >
          &bull;BRANDING
        </a>
                <a
          className="nav-tab"
          style={{ color: colors[19] }}
          href="#tab-contact"
        >
          &bull;CONTACT
        </a>
      </div>
      <div className="stickyToTop" style={{ border: `.2rem solid ${colors[19]}` }}>
        <a href="#nav-home" style={{ color:colors[20] }}>BACK TO TOP</a>
      </div>
    </div>
  );
}

export default Navigation;

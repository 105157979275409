import React from "react";
import BrandingGallery from "../components/BrandingGallery";

const TabBranding = ({ colors, handleHover }) => {
  return (
    <div className="slider" id="tab-branding">
      <h1 
      className="sliderHeader" 
      style={{ color:colors[19] }}
      >BRANDING</h1>
      <h2>
        Creation and development of a brand&apos;s visual identity, including
        logos, typography, color palettes, and overall style guides.
      </h2>
      <BrandingGallery colors={colors}/>
    </div>
  );
};

export default TabBranding;

import React, { useState } from 'react';
import ContactUs from '../components/ContactUs';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faPhone,faComments,faUsers,faEnvelope} from "@fortawesome/free-solid-svg-icons";


const Footer = ({ colors }) => {
  const currentYear = new Date().getFullYear();
  const [selectedButton, setSelectedButton] = useState(-1);
    const handleClickTelephone = (number) => {
    window.location.href = `tel:${number}`;
  };
  //email  
  const handleClick = () => {
  const formWindow  = window.open('', '', 'height=500,width=500');
     ReactDOM.render(<ContactUs />, formWindow.document.body);
  };
  
  //linkedin
      const handleClick2 = (url) => {
    window.open(url, '_blank',);
  };

 const handleMouseEnter = (index) => {
    setSelectedButton(index);
  };
  const handleMouseLeave = () => {
  };

  
  return (

    <footer className="footerContact">
      <div className="slider" id="tab-contact">
      <h1
        className="sliderHeader"
        style={{ color:colors[19] }}
      >CONTACT</h1>
<div className="button-container">
      {[
        {
          label: 'Phone',
          icon: faPhone,
          onClick: () => handleClickTelephone('3236093294'),
        },
        {
          label: 'Email',
          icon: faEnvelope,
          onClick: handleClick,
        },
        {
          label: 'Linkedin',
          icon: faComments,
          onClick: () => handleClick2('https://www.linkedin.com/in/vvagraphics/'),
        },
        {
          label: 'Reviews',
          icon: faUsers,
          onClick: () => handleClick2('https://www.upwork.com/freelancers/~0148b036e7b99081be/'),
        },
      ].map(({ label, icon, onClick }, index) => (
        <button
          key={index}
          className={`contactBtn ${selectedButton === index ? 'enlarged' : ''}`}
          style={{ outline: `.4rem solid ${colors[19]}`, color:colors[20] }}
          onClick={onClick}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <FontAwesomeIcon icon={icon} /> {label}
        </button>
      ))}
    </div>

      <div className="copycontainer" style={{ color: colors[19] }}>
        <p>
          &copy; {currentYear} - VVAGRAPHICS. All rights reserved.
        </p>
      </div>
      </div>
    </footer>
  );
};

export default Footer;

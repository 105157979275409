import React from "react";
import DigitalGallery from "../components/DigitalGallery";

const TabDigital = ({ colors, handleHover }) => {
  return (
    <div className="slider" id="tab-digital">
      <h1
        className="sliderHeader"
        style={{ color:colors[19] }}
      >DIGITAL</h1>
      <h2>
        Designs for digital media such as websites, social media graphics,
        digital advertisements, and email marketing templates.
      </h2>
      <DigitalGallery colors={colors}/>
    </div>
  );
};

export default TabDigital;

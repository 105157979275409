import React, { useState } from 'react';
import Modal from 'react-modal';


const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
  content: {
  position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '95%',
    maxHeight: '95%',
    padding: 0,
    border: 'none',
    background: 'transparent',
    overflow: 'visible',
    pointerEvents: 'none',
  },
};


const images = [
  { src: '/images/print/businesscard.jpg', link: 'https://www.behance.net/gallery/19655405/Stefans-Porfolio/modules/939703835' },
  { src: '/images/print/Fashion-Flingredac3.jpg', link: 'https://www.behance.net/gallery/19655405/Stefans-Porfolio/modules/939699289' },
  { src: '/images/print/bookcoverchem10452.jpg', link: 'https://www.behance.net/gallery/19655405/Stefans-Porfolio/modules/132676349' },
  { src: '/images/print/brochure.jpg', link: 'https://www.behance.net/gallery/19655405/Stefans-Porfolio/modules/939699283' },
];

const PrintGallery = ({ colors }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  function openModal(image) {
    setSelectedImage(image);
    setModalIsOpen(true);
  }

  function closeModal() {
    setSelectedImage(null);
    setModalIsOpen(false);
  }

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div
          className="gallery-item"
          style={{ border: `.2rem solid ${colors[19]}` }}
          key={index}
        >
          <img
            src={image.src}
            alt=""
            onClick={() => openModal(image)}
            style={{ cursor: 'pointer' }}
          />
          <div className="button-wrapper">
            <a
              href={image.link}
              target="_blank"
              rel="noreferrer"
              className="prevLargerBtn"
              style={{
                color: colors[20],
                textDecoration: 'none',
                display: 'inline-block',
                outline: `.2rem solid ${colors[19]}`,
              }}
            >
              LINK
            </a>
          </div>
        </div>
      ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
      >
        {selectedImage && (
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              src={selectedImage.src}
              alt=""
              style={{
                maxWidth: '100vw',
                maxHeight: '100vh',
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              }}
            />
            <button
              className="modalBtn"
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                border: 'none',
                borderRadius: '50%',
                padding: '8px 16px',
                cursor: 'pointer',
                zIndex: 1,
                pointerEvents: 'auto',
              }}
            >
              Close
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PrintGallery;
